<script context="module">
  import { base, options } from '@gb/helpers/api';

  export async function preload({ params, query }) {
    const pageRes = await this.fetch(`${base}`, options);

    if (pageRes.status === 200) {
      const page = await pageRes.json();

      return { page };
    }

    this.error(404, 'leider konnte die seite nicht gefunden werden');
  }
</script>

<script>
  import { lazy } from '@gb/actions/lazy';

  export let page;

  // get image from page
  let image = page.image;
</script>

<style lang="scss">@charset "UTF-8";
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
/**
 * Naming guideline:
 * – breakpoint modifiers are appended to variable names (e. g. $width and
 *   $width-medium)
 * – normal modifieres are prepended (e. g. $width and $medium-width)
 */
.index {
  height: calc(100vh - 2.85rem - 2 * 1.2 * 1rem - 0.6rem);
}
@media (min-width: 1024px) {
  .index {
    height: calc(100vh - 6rem - 2 * 1.2 * 1rem - 0.6rem);
  }
}

.container {
  position: fixed;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 1024px) {
  .container.portrait {
    top: 50%;
  }
}

.image-holder {
  margin: 0;
  position: relative;
  overflow: hidden;
  width: calc(100vw - 1.2rem);
}
.preview-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.preview {
  vertical-align: top;
  width: 100%;
  height: 100%;
  filter: blur(15px);
  transform: scale(1.05);
}

.image {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.image.lazy {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0ms 250ms, opacity 250ms ease-out;
}</style>

<svelte:head>
  <title>{page.title}</title>
  <meta name="description" content="{page.description}">
</svelte:head>

<main class="index">
  <div class="container" class:portrait={image.portrait}>
    <figure
      class="image-holder"
      style="max-width: {image.portrait ? 75 * image.maxWidth / 100 : 85}vh; padding-top: {image.ratio}%;"
      use:lazy>
      <a href="{image.category}/arbeiten">
        <div class="preview-holder">
          <img class="preview" src="{image.preview}" alt="">
        </div>

        <img
          class="image lazy"
          data-src="{image.src}"
          data-srcset="{image.srcset}"
          alt="titelbild"
          name="{image.title}">

        <noscript>
          <img
            class="image"
            src="{image.src}"
            srcset="{image.srcset}"
            alt="titelbild"
            name="{image.title}">
        </noscript>
      </a>
    </figure>
  </div>
</main>


